import { CalculationsTableDetails, SharedValue } from "../../../models/EvaluacionesReparaciones";
import { checkNumber, roundNumber } from "../../inputs";
import TablesGeneralCalculations from "../evaluacionesReparacionesGeneralTableCalculations";

const desarmeInicialStageName = 'Desarme inicial';
const metrologiaStageName = 'Metrología';
const medirJuegoBacklashPlanetarioTaskName = 'Medir juego backlash planetarios';
const metrologiaTuboTorqueAnilloFlingerTaskName = 'Metrología tubo de torque y anillo flinger';
const metrologiaTuboTorqueTableName = 'Metrología tubo de torque';
const metrologiaAnilloFlingerTableName = 'Metrología anillo flinger';
const backlashTableName = 'Backlash';

export const motorTraccion5GEB25TableCalculations = (tableDetails: CalculationsTableDetails) => {
    const generalsCalculations = new TablesGeneralCalculations(tableDetails);
    if (tableDetails.stage === desarmeInicialStageName) {
        if (tableDetails.taskName === 'Medir juego axial de cubo de rueda') {
            if (tableDetails.variableName === 'Cantidad de lainas') {
                let totalLengthSum = 0;
                const extractFirstNumberFromStringInColumnTitle = (columnIndex: number): number | void => {
                    const columnTitle = tableDetails.table.columnsSettings[columnIndex].columnTitle;
                    const numberBeginsIndex = ((): number => {
                        let numberBeginsIndexResponse = -1;
                        for (let i = 0; i < columnTitle.length; i++) {
                            const nextIndex = i + 1;
                            if (checkNumber(columnTitle.charAt(i)).isNumber || ((/[,.]/).test(columnTitle.charAt(i)) && nextIndex < columnTitle.length && checkNumber(columnTitle.charAt(nextIndex)).isNumber)) {
                                numberBeginsIndexResponse = i;
                                break;
                            }
                        }
                        return numberBeginsIndexResponse;
                    })();
                    if (numberBeginsIndex > -1) {
                        return (generalsCalculations.stringToNumber(columnTitle.substring(numberBeginsIndex)));
                    }
                };
                const addLength = (column: number) => {
                    const lengthType = extractFirstNumberFromStringInColumnTitle(column);
                    const inputNumber = generalsCalculations.cellStringToNumber(column);
                    if (lengthType && !isNaN(inputNumber)) {
                        totalLengthSum = totalLengthSum + (lengthType * inputNumber);
                    }
                };
                addLength(1);
                addLength(2);
                addLength(3);
                tableDetails.updateTable(4, roundNumber(totalLengthSum).toString());
                generalsCalculations.standartMeasurements([4], true, { minName: 'Cantidad mínima referencial (" pulgadas)', maxName: 'Cantidad máxima referencial (" pulgadas)' });
            } else if (generalsCalculations.checkVariableOrTaskNameInNamesArray(['Mediciones axial cubo de rueda'])) {
                generalsCalculations.standartMeasurements([1]);
            }
        } else if (generalsCalculations.checkVariableOrTaskNameInNamesArray(['Medir juego axial de planetarios', medirJuegoBacklashPlanetarioTaskName], true)) {
            if (tableDetails.variableName !== backlashTableName) {
                generalsCalculations.standartMeasurements([1]);
            } else {
                tableDetails.updateSharedValues({
                    stageName: desarmeInicialStageName,
                    taskName: medirJuegoBacklashPlanetarioTaskName,
                    tableName: tableDetails.variableName,
                    columnName: 'Valor (" pulgadas)',
                    rowIndex: tableDetails.tableRowIndex,
                    value: tableDetails.table.rows[tableDetails.tableRowIndex][1].content[0]
                });
            }
        }
    } else if (tableDetails.stage === 'Prueba líneas de freno') {
        if (tableDetails.taskName === 'Prueba de estanqueidad a cañerías de frenos') {
            let resultado = 'Malo';
            if (tableDetails.table.rows[tableDetails.tableRowIndex][3].content[0] === 'SÍ') {
                resultado = 'Bueno';
            }
            tableDetails.updateTable(4, resultado);
        }
    } else if (tableDetails.stage === metrologiaStageName) {
        if (tableDetails.taskName === 'Medición cubo de rueda') {
            if (generalsCalculations.checkVariableOrTaskNameInNamesArray(['Metrología cubo de rueda', 'Ángulo del cono'])) {
                generalsCalculations.promedioCalc([1, 2, 3, 4, 5, 6]);
                generalsCalculations.standartMeasurements([7]);
            } else {
                generalsCalculations.standartMeasurements([2]);
            }
        } else if (tableDetails.taskName === metrologiaTuboTorqueAnilloFlingerTaskName) {
            if (generalsCalculations.checkVariableOrTaskNameInNamesArray([metrologiaTuboTorqueTableName, metrologiaAnilloFlingerTableName])) {
                const newPromedioElement = generalsCalculations.promedioCalc([1, 2, 3, 4, 5, 6]);
                tableDetails.updateSharedValues({
                    stageName: metrologiaStageName,
                    taskName: metrologiaTuboTorqueAnilloFlingerTaskName,
                    tableName: tableDetails.variableName === metrologiaTuboTorqueTableName ? metrologiaTuboTorqueTableName : metrologiaAnilloFlingerTableName,
                    columnName: 'Promedio',
                    rowIndex: tableDetails.tableRowIndex,
                    value: newPromedioElement
                });
                generalsCalculations.standartMeasurements([7]);
            } else if (tableDetails.variableName === 'Interferencia') {
                generalsCalculations.standartMeasurements([1]);
            } else if (tableDetails.variableName === 'Control desgaste corona tubo de torque Diámetro PIN: 0,772”') {
                generalsCalculations.promedioCalc([1, 2, 3, 4]);
                generalsCalculations.standartMeasurements([5]);
            } else if (tableDetails.variableName === 'Medidas anillo flinger') {
                generalsCalculations.standartMeasurements([1]);
            }
        } else if (tableDetails.taskName === 'Metrología a piñón solar') {
            if (generalsCalculations.checkVariableOrTaskNameInNamesArray(['Desgaste de spline PIN: 0.240"', 'Desgaste de engranaje PIN: 0.576"'])) {
                generalsCalculations.promedioCalc([1, 2, 3, 4]);
                generalsCalculations.standartMeasurements([5]);
            }
        } else if (generalsCalculations.checkVariableOrTaskNameInNamesArray(['Metrología planetarios A', 'Metrología planetarios B', 'Metrología planetarios C'], true)) {
            if (generalsCalculations.checkVariableOrTaskNameInNamesArray([
                'Metrología eje pista rodamiento recto',
                'Metrología piñón planetario - PIN 0,864”',
                'Metrología corona planetario - PIN 0,800”',
                'Metrología eje pista rodamiento cónico pista A superior',
                'Metrología eje pista rodamiento cónico pista B inferior'
            ])) {
                generalsCalculations.promedioCalc([1, 2, 3, 4]);
                generalsCalculations.standartMeasurements([5]);
            }
        } else if (generalsCalculations.checkVariableOrTaskNameInNamesArray(['Metrología de frame', 'Metrología alojamientos rod. rectos frame', 'Metrología alojamientos rod. cónicos frame'], true)) {
            if (generalsCalculations.checkVariableOrTaskNameInNamesArray([
                'Metrología pista rodamiento inferior N° Rod: 8864953P119',
                'Metrología pista rodamiento superior N° Rod: 8864953P120',
                'Metrología pista rodamiento recto A N° Rod: 8864951P206',
                'Metrología pista rodamiento recto B N° Rod: 8864951P206',
                'Metrología pista rodamiento recto C N° Rod: 8864951P206',
                'Metrología pista rodamiento cónico A N° Rod: 8864961P12',
                'Metrología pista rodamiento cónico B N° Rod: 8864961P12',
                'Metrología pista rodamiento cónico C N° Rod: 8864961P12'
            ])) {
                generalsCalculations.promedioCalc([1, 2, 3, 4]);
                generalsCalculations.standartMeasurements([5]);
            }
        } else if (tableDetails.taskName === 'Medición holgura entre anillo flinger y porta sello') {
            if (generalsCalculations.checkVariableOrTaskNameInNamesArray(['Holgura entre anillo flinger y porta sello', 'Medidas anillo porta sello'])) {
                generalsCalculations.standartMeasurements([1]);
            }
        }
    }
}

export const motorTraccion5GEB25InterferenciaCalculations = (sharedValues: SharedValue[], updateTable: (tableRowIndex: number, tableRowElementIndex: number, value?: string) => void) => {
    let anilloFlingerPromedio = '';
    let tuboTorquePromedio = '';
    sharedValues.forEach((sharedValue) => {
        if (sharedValue.value && sharedValue.stageName === metrologiaStageName && sharedValue.taskName === metrologiaTuboTorqueAnilloFlingerTaskName && sharedValue.columnName === 'Promedio' && sharedValue.rowIndex === 0) {
            if (sharedValue.tableName === metrologiaTuboTorqueTableName) {
                tuboTorquePromedio = sharedValue.value;
            } else if (sharedValue.tableName === metrologiaAnilloFlingerTableName) {
                anilloFlingerPromedio = sharedValue.value;
            }
        }
    });
    if (anilloFlingerPromedio && tuboTorquePromedio) {
        const anilloFlingerPromedioNumber = parseFloat(anilloFlingerPromedio.replace(',', '.'));
        const tuboTorquePromedioNumber = parseFloat(tuboTorquePromedio.replace(',', '.'));
        updateTable(0, 1, `${roundNumber((anilloFlingerPromedioNumber > tuboTorquePromedioNumber) ? (anilloFlingerPromedioNumber - tuboTorquePromedioNumber) : (tuboTorquePromedioNumber - anilloFlingerPromedioNumber))}`);
    } else {
        updateTable(0, 1, '');

    }
};

export const motorTraccion5GEB25DiferenciaBacklashCalculations = (sharedValues: SharedValue[], updateTable: (tableRowIndex: number, tableRowElementIndex: number, value?: string) => void) => {
    const backlashABC: string[] = ['', '', ''];
    const backlashABCNumbers: (number | undefined)[] = [undefined, undefined, undefined];
    sharedValues.forEach((sharedValue) => {
        if (
            sharedValue.value &&
            sharedValue.stageName === desarmeInicialStageName &&
            sharedValue.taskName === medirJuegoBacklashPlanetarioTaskName &&
            sharedValue.tableName === backlashTableName &&
            sharedValue.columnName === 'Valor (" pulgadas)' &&
            ([0, 1, 2].includes(sharedValue.rowIndex))
        ) {
            backlashABC[sharedValue.rowIndex] = sharedValue.value || '';
        }
    });
    if (((): boolean => {
        let medidasValidasCounter = 0;
        backlashABC.forEach((backlash, backlashIndex) => {
            const backlashString = backlash;
            if (backlashString && typeof backlashString === 'string') {
                medidasValidasCounter = medidasValidasCounter + 1;
                backlashABCNumbers[backlashIndex] = parseFloat(backlashString.replace(',', '.'));
            }
        });
        return (medidasValidasCounter > 1);
    })()) {
        if (backlashABCNumbers[0] && backlashABCNumbers[1]) {
            updateTable(0, 1, `${roundNumber((backlashABCNumbers[0] > backlashABCNumbers[1]) ? (backlashABCNumbers[0] - backlashABCNumbers[1]) : (backlashABCNumbers[1] - backlashABCNumbers[0]))}`);
        } else {
            updateTable(0, 1, '');
        }
        if (backlashABCNumbers[1] && backlashABCNumbers[2]) {
            updateTable(1, 1, `${roundNumber((backlashABCNumbers[1] > backlashABCNumbers[2]) ? (backlashABCNumbers[1] - backlashABCNumbers[2]) : (backlashABCNumbers[2] - backlashABCNumbers[1]))}`);
        } else {
            updateTable(1, 1, '');
        }
        if (backlashABCNumbers[2] && backlashABCNumbers[0]) {
            updateTable(2, 1, `${roundNumber((backlashABCNumbers[2] > backlashABCNumbers[0]) ? (backlashABCNumbers[2] - backlashABCNumbers[0]) : (backlashABCNumbers[0] - backlashABCNumbers[2]))}`);
        } else {
            updateTable(2, 1, '');
        }
    } else {
        updateTable(0, 1, '');
        updateTable(1, 1, '');
        updateTable(2, 1, '');
    }
};
